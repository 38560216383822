import React from 'react';

import Layout from '../components/templates/layout';
import SEO from '../components/templates/seo';

const DataPrivacyPage = () => (
  <Layout pageInfo={{ pageName: 'security' }}>
    <SEO title="MissionMe - Informationssicherheitsrichtlinie" />
    <h1 id="datenschutz-hinweise">Informationssicherheitsrichtlinie</h1>
    <h2>1. Zweck und Ziele der Informationssicherheit</h2>
    <p>
      <strong>SCHUTZ</strong> der Informations- und IT-Vermögenswerte von MissionMe, einschließlich aller Computer, mobilen Geräte,
      Netzwerkausrüstungen, Software und sensiblen Daten. Schutz vor internen, externen, absichtlichen oder zufälligen Bedrohungen sowie die
      Minderung der Risiken von Diebstahl, Verlust, Missbrauch, Schaden oder Missbrauch dieser Systeme.
    </p>

    <p>
      <strong>ZUGANGSKONTROLLE</strong>: Gewährleistung, dass Informationen gegen unbefugten Zugriff geschützt sind. Nutzer erhalten nur
      Zugriff auf Ressourcen, zu denen sie ausdrücklich berechtigt sind. Die Zuweisung von Berechtigungen wird streng kontrolliert und
      regelmäßig überprüft.
    </p>

    <p>
      <strong>VERTRAULICHKEIT</strong> der Informationen schützen. Dies umfasst Maßnahmen zur Verhinderung der Offenlegung von Informationen
      an unbefugte Dritte.
    </p>

    <p>
      <strong>INTEGRITÄT</strong> der Informationen sicherstellen. Es werden Vorkehrungen getroffen, um die unbefugte Modifikation von
      Informationen zu verhindern.
    </p>

    <p>
      <strong>VERFÜGBARKEIT</strong> der Informationen für Geschäftsprozesse aufrechterhalten. Es wird sichergestellt, dass autorisierte
      Parteien bei Bedarf auf Informationen zugreifen können.
    </p>

    <p>
      <strong>EINHALTUNG</strong> von nationalen gesetzlichen und regulatorischen Anforderungen sowie von Standards und Best Practices, auch
      über das geforderte Maß hinaus, wenn möglich.
    </p>

    <p>
      <strong>ENTWICKLUNG, PFLEGE UND TESTUNG</strong> von Geschäftskontinuitätsplänen, um sicherzustellen, dass das Unternehmen trotz aller
      Hindernisse auf Kurs bleibt.
    </p>

    <p>
      <strong>SENSIBILISIERUNG UND SCHULUNG</strong> zur Informationssicherheit für alle Mitarbeiter. Die Sensibilisierung und gezielte
      Schulung wird konsequent durchgeführt. Die Verantwortlichkeiten für die Sicherheit werden in Stellenbeschreibungen widergespiegelt und
      die Einhaltung der Sicherheitsanforderungen wird als Teil unserer Kultur erwartet und akzeptiert.
    </p>

    <p>
      <b>SCHUTZ DER MITARBEITER</b> bei der Meldung von Sicherheitsbedenken durch Berichten oder direkten Kontakt mit der Leitung des
      Informationssicherheitsmanagements. Es werden keine Maßnahmen gegen Mitarbeiter ergriffen, es sei denn, die Meldung deutet
      zweifelsfrei auf eine rechtswidrige Handlung, grobe Fahrlässigkeit oder wiederholte absichtliche Missachtung von Vorschriften oder
      Verfahren hin.
    </p>

    <p>
      <b>KONTINUIERLICHE VERBESSERUNG:</b> Das Management von MissionMe hat sich verpflichtet, eine Kultur der kontinuierlichen Verbesserung
      der Informationssicherheit zu fördern und die Anpassungsfähigkeit an sich entwickelnde Herausforderungen und technologische
      Fortschritte zu verbessern.
    </p>

    <p>
      <b>MELDUNG VON SICHERHEITSVORFÄLLEN</b> per E-Mail an <a href="mailto:security@missionme.de">security@missionme.de</a> für alle
      tatsächlichen oder vermuteten Sicherheitsvorfälle.
    </p>

    <h2>2. Durchsetzung, Ausnahmen und Beschwerden</h2>
    <p>
      Nicht-Einhaltung dieser Richtlinie kann disziplinarische Maßnahmen nach sich ziehen, einschließlich, aber nicht beschränkt auf
      informelle oder formelle Verwarnungen. Ausnahmen erfordern eine schriftliche Genehmigung per E-Mail vom Leiter des
      Informationssicherheitsmanagements. Gewährte Ausnahmen erhalten eine zeitlich begrenzte Ausnahmegenehmigung. Beschwerden können
      jederzeit an den Leiter des Informationssicherheitsmanagements unter <a href="mailto:security@missionme.de">security@missionme.de</a>{' '}
      gerichtet werden Eine Reaktion nach Ersteinreichung erfolgt innerhalb von 14 Tagen.
    </p>
  </Layout>
);

export default DataPrivacyPage;
